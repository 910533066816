<template>
	<div class="s-card-container">
		<div class="scc-search-btn">
			<a-input-search
				placeholder="请输入文章标题"
				enter-button="搜索"
				v-model="searchValue"
				@search="onSearch"
			/>
		</div>
		<a-tabs :accesskey="type" @change="(ac) => handleTabChange(ac, true)">
			<a-tab-pane
				v-for="item of classList"
				:key="item._id"
				:tab="item.name"
			>
				<div class="article-list">
					<a-row :gutter="16">
						<a-col
							class="gutter-row"
							:span="4"
							v-for="(fileItem, fileIndex) of fileList"
							:key="fileIndex"
						>
							<a-card
								:hoverable="true"
								@click="goPreviewHandle(fileItem)"
								style="margin-bottom: 8px;"
							>
								<img
									slot="cover"
									class="self-card-cover"
									alt="example"
									:src="fileItem.cover"
								/>
								<a-card-meta>
									<div slot="title" >
										<img
											
											src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_word.svg"
										/>
										<a-tooltip
											placement="top"
											:title="fileItem.title"
										>
											{{ fileItem.title }}
										</a-tooltip>
									</div>
									<template slot="description">
										使用量：{{ fileItem.downloads }}
									</template>
								</a-card-meta>
							</a-card>
						</a-col>
					</a-row>
				</div>
				<div class="article-pagination">
					<a-pagination
						show-size-changer
						:default-current="1"
						:current="pagination.current"
						:pageSize="pagination.pageSize"
						:total="pagination.total"
						:pageSizeOptions="pagination.pageSizeOptions"
						@change="paginationChange"
						@showSizeChange="onShowSizeChange"
					/>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
import {
	getModelTextClass,
	getPoliciesRegulations,
} from '@/api/thinkTank/policiesRegulations'
export default {
	data() {
		return {
			searchValue: null,
			checkedList: [],
			classList: [
				{
					_id: 1,
					name: '国家法规',
				},
				{
					_id: 2,
					name: '地方细则',
				},
			],
			fileList: [],
			pagination: {
				total: 0,
				pageSize: 10,
				current: 1,
				pageSizeOptions: ['10', '20', '50', '100'],
			},
			type: 1,
		}
	},
	created() {
		this.getTableData()
	},
	methods: {
		getTableData() {
			const { pageSize, current } = this.pagination
			const params = {
				pageNum: current,
				pageSize: pageSize,
				title: this.searchValue,
				type: this.type,
			}
			getPoliciesRegulations(params).then((res) => {
				this.pagination.current = res.pageNum
				this.pagination.pageSize = res.pageSize
				this.pagination.total = res.total
				this.fileList = res.list
				console.log(this.fileList);
			})
		},
		onSearch(value) {
			this.getTableData()
		},
		goPreviewHandle(record) {
			this.$router.push(
				`/thinkTank/policiesRegulations/preview/${record._id}`
			)
		},
		handleTabChange(_id, isTab) {
			this.type = _id
			this.getTableData()
		},
		onShowSizeChange(current, pageSize) {
			console.log(current, pageSize)
			this.pagination.pageSize = pageSize
			this.getTableData()
		},
		paginationChange(page, pageSize) {
			this.pagination.pageSize = pageSize
			this.pagination.current = page
			this.getTableData()
		},
	},
}
</script>
<style lang="less" >
.s-card-container {
	.ant-tabs-tabpane {
		background: #fff;
		padding: 16px;
	}
	.scc-search-btn {
		width: 40%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 16px;
	}
	.s-sub-tab-title {
		padding-bottom: 12px;
		border-bottom: 1px solid #eeeeee;
		.category-text {
			color: #333333;
		}
		.ant-tag {
			cursor: pointer;
		}
	}
	.article-list {
		margin-top: 24px;
		.self-card-cover {
			width: 178px;
			height: 230px;
				img {
					height: 100%;
					width: 100%;
				}
		}
		.ant-card-body {
			padding: 8px;
			.ant-card-meta-title {
				img {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}
				font-size: 14px;
			}
			.ant-card-meta-description {
				text-align: right;
			}
		}
	}
	.article-pagination {
		margin-top: 26px;
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		.ant-pagination {
			text-align: center;
		}
	}
}
</style>
